






































































































































































































 import { Notify } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import { AuthentItemType } from "../../Type/index"
 import { AuthentStore } from "../../store/modules/Basics/Authent"
 import { SetUserData,GetUserData,GetAutionList } from "../../Api/Basics/index"
 import Stroage from "../../util/Storage"
 import { Constellation } from "../../util/index"
 import { UserType } from "../../Api/Basics/InterfaceType"
 import { SetUserType } from "../../Type/index"
 import UserInfoTo from "../../components/UserComponent/UserInfoTo.vue"
 import { Vue,Component, } from "vue-property-decorator"
 import area from "../../util/area"
 import ZoomPage from "@/impView/PageSafety";
interface UserInfoType<T>{
  userId:T;
  token:T;
  data:UserType<T>;
  Sex:any;
  State:number;
  SexList:Array<T>;
  MoodList:Array<string>;
  SelList?:Array<any>;
  SelIndex:number ;
  SelTimeIndex: Array<number|string|undefined> | undefined;
  Birthday:T;
  Show:boolean;
  loadingShow:boolean;
  TimeList?:{
    year:Array<string|number>;
    moth:Array<string|number>;
    day:Array<string|number>;
  };
  CollMool:T|undefined;
  SitCodeValue:string;
  AuthentVal:string;
  // AreaList:any;
  initData(data:UserType<T>):void;
  handleChangeShow( state?:T ):void;
  handleSelChange(e:any):void;
  handleSex():void;
  handleBirthday():void;
  handleMood():void;
  handleSit():void;
  handleHome():void;
  handleConfirm(e?:any):void;
  handleCancel():void;
  handleSetUser( data:SetUserType<string|number> ):void;
  handleGetAuthent():void;
  handleFilterAuthent(list:Array<AuthentItemType>):void;
  handleError(data:string):void;
  handleWorker(address?:string):void;
}

enum Sex_{
  "女" = 0,
  "男" = 1
}

interface GetTimeType<T>{
  year:T;
  moth:T;
  day:T;
}
 function GetTime():GetTimeType<Array<string>>{
  let year :Array<string> = [];
  for( let i = 1980; i <= (new Date().getFullYear() | 0); i ++ ){
    year.push( i + " 年" )
  }
  let moth :Array<string> = [];
  for( let i = 1; i <= 12; i ++ ){
    moth.push( i + " 月" )
  }
  let day :Array<string> = [];
  for( let i = 1; i <= 31; i ++ ){
    day.push( i + " 日" )
  }

  return{
    year,moth,day
  }
 }


 @Component({ name:"UserInfo",components:{ UserInfoTo,HeadTop } })
 export default class UserInfo extends ZoomPage implements UserInfoType<string|number>{
  userId = "";
  token = "";
  data = { sex:"0",birthday:null,feeling:"",address:"",hometown:"" };
  Birthday = "";
  State = 0; // 显示哪一个选择器的 状态
  SelList? = [{}];
  SelIndex = 0;
  SelTimeIndex = [ 0,0,0 ];
  DefaultTimeIndex :Array<number|string> = [0,0,0]
  Sex = Sex_;
  SexList = ["女","男",]
  MoodList = ["保密","单身","恋爱中"]
  Show = false;
  loadingShow = true;
  TimeList = { year:["0"],moth:["0"],day:["0"] };
  CollMool = ""
  AreaList :any = {}
  SitCodeValue = "110000"
  AuthentVal = ""



  mounted() {
    this.loadingShow = false
    this.data = Stroage.GetData_ && JSON.parse( Stroage.GetData_("UserData") ) || {}
    this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
    this.token = Stroage.GetData_ && Stroage.GetData_("token")
    this.initData( this.data )
  }

  initData( data:UserType<string|number> ){
    // 生日
    if( data.birthday ){
      let str = data.birthday;
      if ( Boolean( data.constellation ) ){
          str += " " + data.constellation
      }
      this.Birthday = str || "请选择生日"
    }else{
       this.Birthday = "请选择生日"
    }

    // 起动请求
    if ( AuthentStore.GetAuthList.length ){
      this.handleFilterAuthent( AuthentStore.GetAuthList )
    }else{
      this.handleGetAuthent()
    }
  }

  handleChangeShow(){
    this.Show = !this.Show
  }

  handleCancel(){
    this.Show = false
  }

  handleConfirm(e:any){
    let UpData : SetUserType<string|number> = {};
    let num = 0
    let index :number;
    let AddressStr :string;
    switch ( this.State ){
      case 0:
        if( this.data["sex"] && ( Number(this.data["sex"]) != this.SelIndex ) ){
          UpData.sex = this.SelIndex
          this.handleSetUser( UpData )
        }else{
          this.handleError("请修改数据")
        }
        break;
      case 1:
        this.SelTimeIndex.forEach((item,index)=>{
          if(this.DefaultTimeIndex[index] !== item){
            num ++;
          }
        })
        if( num ){
          UpData = {
            birthday:`${this.SelTimeIndex[0]}年 ${this.SelTimeIndex[1]}月 ${this.SelTimeIndex[2]}日`,
            constellation: this.CollMool || "啥也不是"
          }
          this.handleSetUser( UpData )
        }else{
          this.handleError("请修改数据")
        }
        break;
      case 2:
        index = this.MoodList.findIndex( (a:any)=> a == this.data.feeling as string )
        if( index < 0 ){
          index = 0
        }
        if( this.SelIndex != index ){
          UpData = {
            feeling:this.MoodList[this.SelIndex] || "保密"
          }
          this.handleSetUser( UpData )
        }
        break;
      case (3 || 4):
        if( e.length < 3 ){  this.handleError("请选择正确的填写地址"); return; }
        e.forEach( (item:any,index:any)=>{
          if( item.code && item.name ){  num ++; }
        } )
        if( num > 2 ){
          AddressStr = `${ e[0].name } ${ e[1].name } ${ e[2].name }`
          if( this.State == 3 ){
            if( this.data.address == AddressStr ){
              this.handleError("请修改地址数据")
              return;
            }
            UpData = {
              address : AddressStr
            }
          }else if( this.State == 4 ){
            if( this.data.hometown == AddressStr ){
              this.handleError("请修改地址数据")
              return;
            }
            UpData = {
              hometown : AddressStr
            }
          }
          this.handleSetUser( UpData )
        }
        break;
    }
  }

  handleSelChange(e:any){
    let year : any | undefined;
    let moth : any | undefined;
    let day : any | undefined;
    let ActiveList :Array<number>;
    switch( this.State ){
      case 0:
        this.SelIndex = e.getIndexes()[0] | 0
        break;
      case 1:
        ActiveList = e.getIndexes()
        year = this.TimeList.year[ ActiveList[0] ] && this.TimeList.year[ ActiveList[0] ].split(" ")[0]
        moth = this.TimeList.moth[ ActiveList[1] ] && this.TimeList.moth[ ActiveList[1] ].split(" ")[0]
        day = this.TimeList.day[ ActiveList[2] ] && this.TimeList.day[ ActiveList[2] ].split(" ")[0]
        this.SelTimeIndex = [ Number(year),Number(moth),Number(day) ]
        this.CollMool = Constellation(year+"-"+moth+"-"+day)
        console.log( 123,year,moth,day,this.CollMool )
        break;
      case 2:
        this.SelIndex = e.getIndexes()[0] | 0
        break;
    }

    console.log( e,e.getIndexes() )
  }

  handleSex(){
    this.SelList = this.SexList
    this.SelIndex = Number(this.data["sex"]) === 1 ? 1 :0;
    this.State = 0;
    this.handleChangeShow()
  }

  handleBirthday(){
    this.State = 1
    let timeList : GetTimeType<Array<string>> = GetTime()
    this.TimeList = timeList
    let time = new Date()
    if ( !!this.data.birthday ) {
      let reg = /\d+/g;
      let time_  = String(this.data.birthday).match(reg)
      this.SelList = [
        {
          values:timeList.year ,
          defaultIndex:time_ && Number(time_[0]) -1
        },{
          values:timeList.moth ,
          defaultIndex:time_ && Number(time_[1]) -1
        },{
          values:timeList.day ,
          defaultIndex:time_ && Number( time_[2] ) -1
        }
      ]
      this.DefaultTimeIndex = ( [
        (time_ && Number(time_[0]) || 0),
        (time_ && Number(time_[1]) || 0),
        (time_ && Number(time_[2]) || 0),
      ] )
    }else{
      this.SelList = [
        {
          values:timeList.year ,
          defaultIndex:(time.getFullYear() - 1)
        },{
          values:timeList.moth ,
          defaultIndex:(time.getMonth())
        },{
          values:timeList.day ,
          defaultIndex:(time.getDate() - 1 )
        }
      ]
    }
    this.handleChangeShow()
    console.log("这是打印 选择生日")
  }

  handleMood(){
    this.State = 2
    this.SelList = this.MoodList
    let index = this.MoodList.findIndex( (a:any)=> a == this.data.feeling as string )
    if( index < 0 ){
      index = 0
    }
    this.SelIndex = index
    this.handleChangeShow()
    console.log("打印 选择感情状况")
  }

  handleSit(){
    this.handleWorker(this.data.address)
    this.State = 3
    this.AreaList = area
    this.handleChangeShow()
  }

  handleHome(){
    this.handleWorker(this.data.hometown)
    this.State = 4
    this.AreaList = area

    this.handleChangeShow()
  }

  handleSetUser( data:SetUserType<string|number> ){
    console.log( data, )
    this.loadingShow = true;
    new Promise( (resolve,reject)=>{
      SetUserData({
        userId:this.userId,
        token:this.token
      },data).then( (res:any)=>{
        if( res.message.code === "200" ){
          resolve(true)
        }else{
          reject(res)
        }
      } )
    } ).then((res:any)=>{
        GetUserData({
          userId:this.userId,
          token:this.token
        }).then((res:any)=>{
           this.loadingShow = false;
           this.handleCancel()
          if( res.message.code === "200" ){
            Stroage.SetData_ && Stroage.SetData_("UserData",JSON.stringify( res.data ))
            // 视图 初始化
            this.data = res.data
            this.initData( res.data )
          }else{
            this.handleError( res.message.msg )
          }
        })
    }).catch((cat:any)=>{
       this.handleError( cat.message.msg )
    })
  }

  handleGetAuthent(){
    GetAutionList({
      userId:this.userId,
      token:this.token
    }).then((res:any)=>{
      if( res.message.code === "200" ){
        AuthentStore.SetAuthList( res.data )
        this.handleFilterAuthent( res.data )
      }else{
        this.handleError( res.message.msg )
      }
    })
  }

  handleFilterAuthent( data:Array<AuthentItemType> ){
    let FillList :Array<any> = [],
        NoList :Array<any> = [],
        AwaitList :Array<any> = [],
        NotList :Array<any> = [];
    if ( Array.isArray( data ) ){
            data.forEach( (item,index)=>{
                switch ( Number(item.status) ) {
                    case 0:
                        FillList.push( item )
                        break;
                    case 1:
                        NoList.push( item )
                        break;
                    case 2:
                        AwaitList.push( item )
                        break;
                    case 3:
                        NotList.push( item )
                        break;
                }
            } )
            let Str = "";
            // 已认证成功
            if( FillList.length ){ // AuthentVal
                Str = "已认证"
                FillList.forEach((item,index)=>{
                    Str += " "+item.name
                })
                this.AuthentVal = Str
                return
            }
            // 已认证中
            if ( AwaitList.length ){
                Str = "审核中"
                AwaitList.forEach( (item,index)=>{
                    Str += " " + item.name
                } )
                this.AuthentVal = Str
                return
            }
            // 审核失败
            if ( NoList.length ){
                Str = "审核失败"
                NoList.forEach( (item,index)=>{
                    Str += " " + item.name
                } )
                this.AuthentVal = Str
                return
            }
            // 未审核
            this.AuthentVal = "无 未加入三易校园认证"
        }
  }

  handleError( data:string ){
    let str = ""
    if ( data.length ){
        str = data
    }else{
        str = "网络繁忙..."
    }
    Notify({
        message: str,
        color: 'white',
        background: "rgba(0,0,0,.6)",
    });
  }

  handleWorker( address:string ){
    if( !address ){ this.SitCodeValue = "110000"; return;}
    import("../../Worker/worker").then(res=>res.FilterAddCode(address,this.AreaList)).then(res=>{
      if( res ){
        this.SitCodeValue = res
      }
    })
  }

  beforeRouteLeave(to:any,from:any,next:any){
    console.log( to,from,next )
  }
 }
