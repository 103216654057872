






















import { Vue, Component, Prop, Emit } from "vue-property-decorator";

interface UserInfoToType<T> {
  RightIcon: T;
  defaultImg: T;
}

@Component({ name: "UserInfoTo" })
export default class UserInfoTo extends Vue implements UserInfoToType<string> {
  RightIcon = require("../../assets/icon/Basics/Right.png");
  defaultImg = require("../../assets/icon/Basics/default.png");

  @Prop(String)
  title?: string;

  get GetTitle(): string {
    if (this.title) {
      return this.title;
    } else {
      return "这是标题";
    }
  }

  @Prop(String)
  content?: string;

  get GetContent() {
    if (this.content) {
      return this.content;
    } else {
      return "这是描述内容";
    }
  }

  @Prop(Boolean)
  contentShow?: boolean;

  get GetContShow() {
    if (this.contentShow) {
      return false;
    } else {
      return true;
    }
  }

  @Prop()
  src?: string;

  get GetSrc() {
    if (this.src) {
      return this.src;
    } else {
      return this.defaultImg;//require("../../assets/icon/Basics/default.png");
    }
  }

  @Prop(Boolean)
  photoShow?: boolean;

  get GetPhotoShow() {
    if (this.photoShow) {
      return true;
    } else {
      return false;
    }
  }

  @Prop(Boolean)
  rightShow?: boolean;

  get GetRightShow() {
    if (this.rightShow) {
      return false;
    } else {
      return true;
    }
  }

  @Prop(String)
  nav?: string;

  @Prop(Boolean)
  clickBool?: boolean;

  get GetClickBool() {
    if (this.clickBool) {
      return this.clickBool;
    } else {
      return false;
    }
  }

  handleToPath() {
    if (this.GetClickBool) {
      this.toPath();
    } else {
      if (this.nav) {
        this.$router.push({
          path: this.nav,
        });
      }
    }
  }

  @Emit("toPath")
  toPath() {
    return true;
  }
}
